import { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
// import { ItemTypes } from './ItemTypes.js'
// 



import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';



import update from 'immutability-helper'
import { useCallback, useState } from 'react'
// import { Card } from './Card.js'

import { render } from 'react-dom'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function DNDList(props) {
    return (
        <DndProvider backend={HTML5Backend}>
            <Container {...props} />
        </DndProvider>
    )
}


export default DNDList


const styleContainer = {
    minWidth: 500,
}
const styleCard = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
}


export const Container = ({ removeItem, arangeList, list }) => {
    {
        const [cards, setCards] = useState(list || [])

        const moveCard = useCallback((dragIndex, hoverIndex) => {
            arangeList((prevCards) =>
                update(prevCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevCards[dragIndex]],
                    ],
                }),
            )
        }, [])
        const renderCard = useCallback((card, index) => {
            return (
                <Card
                    key={card.id}
                    index={index}
                    id={card.id || card._id}
                    text={card.name}

                    moveCard={moveCard}
                    removeCard={removeItem}
                />
            )
        }, [])




        return (
            <>
                <div style={styleContainer}>
                    <div style={{
                            margin: 0,
                            fontWeight: 500,
                            fontSize: "1.25rem",
                            lineHeight: 1.6,
                            letterSpacing: "0.0075em",
                            flex: "1 1 100%"
                    }}>Exhibits in route</div>
                    {list.map((card, i) => renderCard(card, i))}
                    </div>
            </>
        )
    }
}

export const ItemTypes = {
    CARD: 'card',
}


export const Card = ({ id, text, index, moveCard, removeCard }) => {
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        //   <div ref={ref} style={{ ...styleCard, opacity }} data-handler-id={handlerId}>
        //     {text}
        //   </div>
        <ListItem className="drag-handle" ref={ref} style={{ ...styleCard, opacity }} data-handler-id={handlerId}>

            <ListItemIcon >
                <DragIndicatorIcon />
            </ListItemIcon>
            <ListItemText primary={`${index+1}. ${text}`} />
            
                <ListItemIcon onClick={() => { removeCard(id) }}>
                    <DeleteIcon />
                </ListItemIcon>
        </ListItem>
    
    )
}