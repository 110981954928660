import React, { useContext, useEffect } from "react";
import { AppBarContext } from "../context/AppBarContext";

export default () => {
    const { setAppBarTitle, setAppBarSearch } = useContext(AppBarContext);

    useEffect(() => {
        setAppBarTitle("Home");
        setAppBarSearch(null)
      }, []);
    

    return (
        <div>Home</div>
        )
}