import React, { useContext, useEffect, useState, useCallback } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Navigate, useNavigate, Outlet } from 'react-router';

import { RouteAPI, base_url } from "../api/"
import ListView from "../components/ListView"
import FuzzySearch from 'fuzzy-search';
import { AppBarContext } from "../context/AppBarContext";

export default () => {

    const { setAppBarTitle, setAppBarSearch } = useContext(AppBarContext);
    const navigate = useNavigate();

    const [routeList, setRouteList] = useState([])
    const [searchQuery, setSearchQuery] = useState("")

    const onSearch = (e) => {
        setSearchQuery(e)
    }
    useEffect(() => {
        setAppBarTitle("Routes");
        setAppBarSearch({onSearch:onSearch})
        RouteAPI.getList().then(({list})=> {
            setRouteList(list)
        }).catch(e => console.log(e))
    }, []);


    const searcher = new FuzzySearch(routeList, ['name'], {
        sort: true,
    });

    var list = []
    if(searchQuery)
        list = searcher.search(searchQuery);
    else 
        list = routeList

    return (<>
        
            {
                routeList.length ?
                    <ListView list={list} /> :
                    <Box sx={{
                        display: "flex",
                        m: 4
                    }}>
                    <Paper elevation={3} sx={{
                        p: 4, flexGrow: 1, minHeight: 200, alignItems: "center",
                        justifyContent: "center", display: "flex"
                    }}>
                        <Typography variant="h5" noWrap component="div" sx={{ textTransform: "uppercase" }}>
                            Empty
                        </Typography>
                    </Paper>
                    </Box>
            }
        
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            m: 4
        }}>
            <Fab color="secondary" aria-label="add" onClick={() => { navigate("./create") }}>
                <AddIcon />
            </Fab>
        </Box>
    </>)
}


