// https://react-svgr.com/playground/
import { useRef, useState, useEffect } from 'react';
import { useWindowSize } from '@react-hook/window-size'
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_NONE, TOOL_PAN, TOOL_AUTO, fitSelection, zoomOnViewerCenter, fitToViewer, UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import { AutoSizer } from "react-virtualized";

import MapSvg from './MapSVG'




export default ({ config, positionList, position, setPosition }) => {

    const { positionPointRadius, positionPointTextSize } = {
        positionPointRadius: 5,
        positionPointTextSize: "7.5px",
        ...config
    }





    const Viewer = useRef(null);
    const [tool, setTool] = useState(TOOL_AUTO)
    const [value, setValue] = useState(INITIAL_VALUE)
    const [zoom, setZoom] = useState(1);


    // const [width, height] = useWindowSize({initialWidth: 1000, initialHeight: 500})

    // console.log("Viewer", Viewer)
    useEffect(() => {
        setTimeout(() => {

            Viewer.current.fitToViewer("center", "center");
            // Viewer.current.fitToViewer("bottom", "bottom");
            // Viewer.current.zoomOnViewerCenter(1.1)
            // Viewer.current.fitToViewer("center", "bottom");

        }, 100)
    }, []);


    const svgWidth = 1395.57;
    const svgHeight = 1083.06;
    const height = 500;
    const width = svgWidth / svgHeight * height;
    const scaleFactorMin = Math.min(height / svgHeight, width / svgWidth)

    return <div className="map-container" style={{ display: "flex", justifyContent: "center" }}>
        <ReactSVGPanZoom
            ref={Viewer}
            // style={{ border: "2px solid #949494" }}
            detectAutoPan={false}
            detectWheel={true}
            preventPanOutside={true}
            width={width} height={height}
            background="#ffff"
            miniatureProps={{ position: "none" }}
            tool={tool} onChangeTool={setTool}
            value={value} onChangeValue={setValue}
            scaleFactorMin={scaleFactorMin}
            scaleFactorMax={10}
            onZoom={currentValue => {
                console.log('onZoom', value);
                console.log('onZoom->a', value.a);
                console.log('onZoom->d', value.d);
                // if(currentValue.a < scaleFactorMin || currentValue.d < scaleFactorMin){
                //     setValue({...value})
                // }
            }}
            onPan={value => {
                console.log('onPan', value)
                console.log('onPan->e', value.e)
                console.log('onPan->f', value.f)
                // setValue({
                //     ...value,
                //     e:0,
                //     // limit up/down panning to within the SVG
                //     f: value.f>0?0
                //         :value.f<0-scaledMaxHeight?0-scaledMaxHeight
                //         :value.f
                //     })
            }}
            onClick={event => {
                console.log(`x: ${event.x}, y: ${event.y}`)
                console.log(event)

                if (setPosition) {
                    if (position) { setPosition({ ...position, x: event.x, y: event.y }) }
                    else { setPosition({ x: event.x, y: event.y, z: 0 }) }
                }
            }}
        >
            <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
                <MapSvg>
                    {
                        position ? <circle cx={position.x} cy={position.y} r={positionPointRadius}
                            stroke="red"
                            strokeWidth="2.5"
                            fill="red" /> : null
                    }
                    {
                        positionList ? positionList.map((data, index) => {
                            if (data && data.position && data.position.x && data.position.y)
                                if (data.cb)
                                    return (<>
                                        <g onClick={data.cb}>
                                            <circle cx={data.position.x} cy={data.position.y} r={positionPointRadius}
                                                stroke="red"
                                                strokeWidth="2.5"
                                                fill="red" />
                                            <text x={data.position.x} y={data.position.y} text-anchor="middle" stroke="blue" strokeWidth="0.5px" fontSize={positionPointTextSize} dy=".05em">{(index + 1).toString()}</text>
                                        </g>
                                    </>)
                                else
                                    return (<>
                                        <circle cx={data.position.x} cy={data.position.y} r={positionPointRadius}
                                            stroke="red"
                                            strokeWidth="2.5"
                                            fill="red" />
                                        <text x={data.position.x} y={data.position.y} text-anchor="middle" stroke="blue" strokeWidth="0.5px" fontSize={positionPointTextSize} dy=".05em">{(index + 1).toString()}</text>
                                    </>)
                            else
                                return null
                        }) : null
                    }
                </MapSvg>
            </svg>
        </ReactSVGPanZoom>
    </div>


}

