import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useLocation } from "react-router-dom";

import { useSearchParams } from 'react-router-dom';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import StarIcon from '@mui/icons-material/Star';



export default ({ list, routePath }) => {

  const navigateRoute = typeof routePath == "string"? routePath : "";
  const cellKeys = {
    index: "string",
    name: "string"
  }

  const iconKeys = {
    highlighted: "boolean"
  }

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({"page":"0", "count":"10" });




  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  useEffect(()=>{
    if(searchParams.has('page')) {
      setPage(parseInt(searchParams.get('page')))
    } else {
      setPage(0)
    }

    if(searchParams.has('count')) {
      setRowsPerPage(parseInt(searchParams.get('count'), 10))
    } else {
      setRowsPerPage(10)
    }

  }, [searchParams])

  // useEffect(() => { setPage(0) }, [list])

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };


  const handleChangePage = (event, newPage) => {
    setSearchParams({
      'page': parseInt(newPage, 10),
      'count': parseInt(rowsPerPage)
    });
  };

  const handleChangeRowsPerPage = (event) => {

    setSearchParams({
      'page': 0,
      'count':parseInt(event.target.value, 10)
    });
    
  };

  

  const TableCellList = (item, cellKeys) => {

    const getCellValue = (item, key) => { 
      switch (cellKeys[key].toLowerCase()) {
        case "string":
          return item[key].toUpperCase();
        default:
          return item[key];
      }
    }
    
    return Object.keys(cellKeys).map(key => 
      item[key] ?
      <TableCell
        component="th"
        id={key}
        scope="row"
        padding="none">
        {getCellValue(item, key)}
      </TableCell>:
      <TableCell
      component="th"
      id={key}
      scope="row"
      padding="none">
      {""}
    </TableCell>
    )
  }




  return (<Fragment>
    <TableContainer>
      <Table
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableBody>
          {list
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => {

                item.index = (index+1).toString()

              // ROWS
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell padding="checkbox"></TableCell>

                  {TableCellList(item,cellKeys)}

                  {
                    item.highlighted ? <TableCell align="right"><StarIcon color="primary" /></TableCell> : <TableCell
                    component="th"
                    scope="row"
                    padding="none">
                    {""}
                  </TableCell>
                  }


                  {/* <TableCell align="right">{`${group.productList.length} objects`}</TableCell> */}
                  {/* <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell> */}


                  <TableCell size="small" align="right">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={()=>{
                        navigate(`${navigateRoute}${item.id || item._id}`)
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={list.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Fragment>)
}

