
let BASE_URL = ""
if (document.location.origin.includes("armus.smart-tech.hr")) {
    BASE_URL = "https://api.armus.smart-tech.hr/"
} else {
    BASE_URL = "http://localhost:3001/"
}
BASE_URL = "https://api.armus.smart-tech.hr/"

export const base_url = BASE_URL;
const api_route = `api/v1/private`

export const getToken = () => {
    return localStorage.getItem('admin_token');
}

export const setToken = (token) => {
    return localStorage.setItem('admin_token', token);
}



const POST_OPTS = ContentType => {

    const options = {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            // 'Authorization': `Bearer ${getToken()}`,
            'Content-Type': ContentType || 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }

    if (ContentType == "multipart/form-data") {
        delete options.headers['Content-Type']
    }
    return options
}

export const ExhibitAPI = {
    getList: async (id) => {
        try {
            const options = {
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/exhibit/getList`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getHighlighted: async (id) => {
        try {
            const options = {
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/exhibit/getHighlighted`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getByID: async (id) => {
        try {
            const options = {
                body: JSON.stringify({ id }),
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/exhibit/getByID`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    create: async (payload, images, audio) => {

        const formData = new FormData();

        formData.append('payload', JSON.stringify({ ...payload }))
        for (let image in images) {
            formData.append('images', images[image])
        }

        // So basicly I just add aditional data about upload
        // This must be added before files
        if (audio) {
            var audioData = {}
            Object.keys(audio).forEach(lang => {
                audioData[lang] = audio[lang].map(a => a.route)
            })
            formData.append('audio-data', JSON.stringify(audioData))
        }

        for (let index in audio.hr) {
            formData.append('audio-hr', audio.hr[index].file)
        }
        for (let index in audio.en) {
            formData.append('audio-en', audio.en[index].file)
        }


        try {
            const response = await fetch(`${base_url}${api_route}/exhibit/create`, {
                body: formData,
                ...POST_OPTS('multipart/form-data')
            });
            return await response.json();
        } catch (error) {
            console.error(error)
        }
    },
    update: async (id, payload, images, audio) => {
        const formData = new FormData();
        formData.append('id', id)
        formData.append('payload', JSON.stringify({ ...payload }))
        for (let image in images) {
            formData.append('images', images[image])
        }

        // So basicly I just add aditional data about upload
        // This must be added before files
        if (audio) {
            var audioData = {}
            Object.keys(audio).forEach(lang => {
                audioData[lang] = audio[lang].map(a => a.route)
            })
            formData.append('audio-data', JSON.stringify(audioData))
        }

        for (let index in audio.hr) {
            formData.append('audio-hr', audio.hr[index].file)
        }
        for (let index in audio.en) {
            formData.append('audio-en', audio.en[index].file)
        }

        try {
            const response = await fetch(`${base_url}${api_route}/exhibit/update`, {
                body: formData,
                ...POST_OPTS('multipart/form-data')
            });
            return await response.json();
        } catch (error) {
            console.error(error)
        }


    },
    remove: async (id) => {
        try {
            const options = {
                body: JSON.stringify({ id }),
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/exhibit/remove`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}
export const RouteAPI = {
    getList: async () => {
        try {
            const options = {
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/route/getList`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getByID: async (id) => {
        try {
            const options = {
                body: JSON.stringify({ id }),
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/route/getByID`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    create: async (payload) => {
        try {
            const response = await fetch(`${base_url}${api_route}/route/create`, {
                body: JSON.stringify({ payload }),
                ...POST_OPTS()
            });
            return await response.json();
        } catch (error) {
            console.error(error)
            return null;
        }
    },
    update: async (id, payload) => {
        try {
            const response = await fetch(`${base_url}${api_route}/route/update`, {
                body: JSON.stringify({ id, payload }),
                ...POST_OPTS()
            });
            return await response.json();
        } catch (error) {
            console.error(error)
            return null;
        }
    },
    remove: async (id) => {
        try {
            const options = {
                body: JSON.stringify({ id }),
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/route/remove`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}
export const AdminAPI = {
    login: async (email, password) => {
        const options = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ email, password }) // body data type must match "Content-Type" header
        }
        try {

            const response = await fetch(`${base_url}${api_route}/admin/login`, options);

            if (response.status == 401) {
                return {
                    error: "Krivi email ili lozinka"
                }
            }
            const { user, token } = await response.json();

            if (token && user) {
                setToken(token)
                return { user }
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    authorise: async () => {
        const token = getToken();
        if (!token) return null;

        const options = {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Authorization': `Bearer ${token}`
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }
        try {
            const response = await fetch(`${base_url}${api_route}/admin/authorise`, options);

            const { user } = await response.json();
            return user;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    logout: async () => {
        setToken(null)
    }
}
export const BeaconAPI = {
    getList: async (id) => {
        try {
            const options = {
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/beacon/getList`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getByID: async (id) => {
        try {
            const options = {
                body: JSON.stringify({ id }),
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/beacon/getByID`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    create: async (payload) => {
        try {
            const response = await fetch(`${base_url}${api_route}/beacon/create`, {
                body: JSON.stringify({ payload }),
                ...POST_OPTS()
            });
            return await response.json();
        } catch (error) {
            console.error(error)
            return null;
        }
    },
    update: async (id, payload) => {
        try {
            const response = await fetch(`${base_url}${api_route}/beacon/update`, {
                body: JSON.stringify({ id, payload }),
                ...POST_OPTS()
            });
            return await response.json();
        } catch (error) {
            console.error(error)
            return null;
        }
    },
    remove: async (id) => {
        try {
            const options = {
                body: JSON.stringify({ id }),
                ...POST_OPTS()
            }
            const response = await fetch(`${base_url}${api_route}/beacon/remove`, options);
            return await response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}