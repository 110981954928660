import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom"

import { v4 as uuid } from 'uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageUploadForm from "../components/ImageUploadForm";
import MapViewer from "../components/MapViewer"


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


import Button from '@mui/material/Button';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

import { Navigate, useNavigate } from 'react-router';


import { AppBarContext } from "../context/AppBarContext";

import { RouteAPI, ExhibitAPI, base_url } from "../api/"

import DNDList from '../components/DNDList'
import SearchTable from '../components/SearchTable'


export default () => {

    let { routeID } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [openDataLangForm, setOpenDataLangForm] = useState(false);
    const { setAppBarTitle, setAppBarSearch } = useContext(AppBarContext);



    const [name, setName] = useState("");
    const [title, setTitle] = useState({ hr: "", en: "" });
    const [shortdesc, setShortdesc] = useState({ hr: "", en: "" });
    const [description, setDescription] = useState({ hr: "", en: "" });
    const [duration, setDuration] = useState(0);
    const [active, setActive] = useState(true);
    const [exhibits, setExhibits] = useState([]);

    const [exhibitItemList, setExhibitItemList] = useState([])
    // const [ exhibitList, setExhibitList ] = useState([])





    const handleCreate = () => {
        setLoading(true)
        RouteAPI.create({
            name,
            title,
            shortdesc,
            description,
            duration,
            active,
            exhibits
        }).then(({ route, error }) => {

            if (error) alert(error)
            else {
                navigate(`../${route.id || route._id}`)
            }
        }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })

    }

    const handleUpdate = () => {
        setLoading(true)
        RouteAPI.update(routeID, {
            name,
            title,
            shortdesc,
            description,
            duration,
            active,
            exhibits
        }).then(({ route, error }) => {

            if (error) alert(error)
            else alert("Saved")
            // Destruct route
            setName(route.name)
            setTitle(route.title)
            setShortdesc(route.shortdesc)
            setDescription(route.description)
            setDuration(route.duration)
            setActive(route.active)
            setExhibits(route.exhibits)


            setAppBarTitle(route.name)
        }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleRemove = () => {
        setLoading(true)
        RouteAPI.remove(routeID)
            .then(({ route }) => {
                navigate(`../`)
            }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleGetByID = () => {
        setLoading(true)
        RouteAPI.getByID(routeID)
            .then(({ route }) => {
                setName(route.name)
                setTitle(route.title)
                setShortdesc(route.shortdesc)
                setDescription(route.description)
                setDuration(route.duration)
                setActive(route.active)
                setExhibits(route.exhibits)

                setAppBarTitle(route.name)
            }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleLoadExhibitList = () => {
        setLoading(true)
        ExhibitAPI.getList().then(({ list }) => {
            setExhibitItemList(list)
        }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setAppBarSearch(null)
        handleLoadExhibitList()
        if (routeID) {
            handleGetByID()
        } else {
            setAppBarTitle("Add new Route")
        }
    }, [routeID])



    const addExhibit = (exhibitIDList) => {
        setExhibits([...exhibits, ...exhibitIDList])
    }
    const removeExhibit = (exhibitID) => {

        setExhibits(prevExhibits => [...prevExhibits.filter(id => id != exhibitID)])

    }
    const arangeExhibits = (exhibitIDList) => {
        setExhibits(exhibitIDList)
    }


    // useEffect(() => {
    //     console.log("exhibits", exhibits)
    // }, [exhibits])

    /*  Function */
    const filterExhibitList = (exhibitList, exhibitsIDList, exclude) => {
        const exhibitsList_f = [];

        exhibitList.forEach(exhibit => {
            if (exhibitsIDList.includes(exhibit._id) && !exclude)
                exhibitsList_f.push(exhibit)
            else if (!exhibitsIDList.includes(exhibit._id) && exclude) {
                exhibitsList_f.push(exhibit)
            }
        })
        if (!exclude) {
            return exhibitsList_f.sort((a, b) => exhibitsIDList.indexOf(a._id) - exhibitsIDList.indexOf(b._id));
        } else {
            return exhibitsList_f;
        }
    };


    const positionList = useMemo(()=>{
        let positions = [];
        exhibits.forEach(exhibitID => {
            let exhibitItem = exhibitItemList.find(exhibit => (exhibit.id || exhibit._id) == exhibitID)
            if(exhibitItem && exhibitItem.position) positions.push(exhibitItem.position)
        })
        return positions;
    }, [exhibits])

    return (

        <Grid container >
            <Row>
                <Grid md={6} >
                    <NameForm name={name}
                        setName={setName}
                        duration={duration}
                        setDuration={setDuration}
                        active={active}
                        setActive={setActive}
                    />
                </Grid>
                <Grid md={6} >
                    <ButtonControls routeID={routeID}
                        handleUpdate={handleUpdate}
                        handleCreate={handleCreate}
                        handleRemove={handleRemove} />

                </Grid>
            </Row>
            <Row>
                <DataLangForm lang={"hr"}
                    open={openDataLangForm}
                    onOpenChange={setOpenDataLangForm}
                    title={title}
                    setTitle={setTitle}
                    shortdesc={shortdesc}
                    setShortdesc={setShortdesc}
                    description={description}
                    setDescription={setDescription}
                />
                <DataLangForm lang={"en"}
                    open={openDataLangForm}
                    onOpenChange={setOpenDataLangForm}
                    title={title}
                    setTitle={setTitle}
                    shortdesc={shortdesc}
                    setShortdesc={setShortdesc}
                    description={description}
                    setDescription={setDescription}
                />
            </Row>


            <Row style={{ width: "90%", margin: "auto" }}>
                <MapViewer positionList={positionList} />
            </Row>

            
            <Row>
                <Grid md={6} >
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        ml: 2,
                        mr: 2
                    }}>
                        <DNDList removeItem={removeExhibit}
                            arangeList={arangeExhibits}
                            list={filterExhibitList(exhibitItemList, exhibits)}
                        />
                    </Box>
                </Grid>
                <Grid md={6} >
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        ml: 2,
                        mr: 2
                    }}>
                        <SearchTable itemList={filterExhibitList(exhibitItemList, exhibits, true)} onTransfer={addExhibit} />
                    </Box>
                </Grid>
            </Row>

        </Grid >
    )
}


const ButtonControls = ({ routeID, handleCreate, handleUpdate, handleRemove }) => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "auto",
            ml: 2,
            mr: 2
        }}>
            {routeID ?
                <>
                    <Button sx={{ m: 1 }} color="success" variant="contained" onClick={handleUpdate}>Update</Button>
                    <Button sx={{ m: 1 }} color="error" variant="contained" onClick={handleRemove}>Remove</Button>
                </>
                :
                <>
                    <Button sx={{ m: 1 }} color="success" variant="contained" onClick={handleCreate}>Create</Button>
                </>}

        </Box>
    )
}


const NameForm = ({ name, setName, duration, setDuration, active, setActive }) => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "auto",
            ml: 2,
            mr: 2
        }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={8}>
                    <TextField id="standard-basic" fullWidth label="Name" variant="standard" value={name} onChange={e => setName(e.target.value)} />
                </Grid>
                <Grid xs={4}>
                    <FormGroup sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                        <FormControlLabel control={<Switch defaultChecked checked={active} onChange={e => setActive(e.target.checked)} />} label="Active" />
                    </FormGroup>
                </Grid>
                <Grid xs={8}>
                    <TextField fullWidth label="Duration" variant="standard" value={duration} onChange={e => setDuration(e.target.value)} />
                </Grid>
            </Grid>
        </Box>
    )
}

const DataLangForm = ({ lang, open, onOpenChange, ...props }) => {
    const {
        title,
        setTitle,
        shortdesc,
        setShortdesc,
        description,
        setDescription
    } = props

    return (
        <Box sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ml: 2,
            mr: 2
        }}>

            <Accordion sx={{
                width: "100%",
                marginBottom: "15px",
                p: 2
            }}
                expanded={open} onChange={() => { onOpenChange(!open) }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{`Description data ${lang.toUpperCase()}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField id="standard-basic" fullWidth
                        label="Title" variant="standard"
                        value={title[lang]}
                        onChange={e => setTitle({ ...title, [lang]: e.target.value })} />
                    <TextField
                        id="outlined-textarea"
                        label="Short description"
                        multiline
                        fullWidth
                        variant="standard"
                        value={shortdesc[lang]}
                        onChange={e => setShortdesc({ ...shortdesc, [lang]: e.target.value })}
                        rows={4}
                    />
                    <TextField
                        id="outlined-textarea"
                        label="Description"
                        multiline
                        fullWidth
                        variant="standard"
                        value={description[lang]}
                        onChange={e => setDescription({ ...description, [lang]: e.target.value })}
                        rows={8}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}


const Row = ({ children, style }) => {
    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            mt: 3,
            mb: 3,
            ...style
        }}>
            {children}
        </Box>
    )
}


const converPath = (path) => {
    return `${base_url}${path}`
}