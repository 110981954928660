import { useEffect, useMemo, useState } from "react";
import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';




import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: '125px'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


const UploadFile = ({ acceptType, maxFiles, uploadFiles, setUploadFiles }) => {

    const [error, setError] = useState();
    const [files, setFiles] = useState([]);

    const [uploadDisabled, setUploadDisabled] = useState(maxFiles - uploadFiles.length > 0 ? false : true);

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: acceptType,
        maxFiles: maxFiles,
        disabled: uploadDisabled,
        onDrop: acceptedFiles => {
            const newFilesAdded = acceptedFiles.map(file => {
                return Object.assign(file, { preview: URL.createObjectURL(file) })
            })
            // setFiles([...files, ...newFilesAdded])
            setUploadFiles([...uploadFiles, ...newFilesAdded])
        }
    });


    const onFileRemove = (file) => {
        const index = uploadFiles.indexOf(file);
        if (index != -1) {
            uploadFiles.splice(index, 1)
            setUploadFiles([...uploadFiles])
        }
    }


    useEffect(() => {
        // console.log("FILES CHANGED", files);
        // console.log(fileRejections)
        setUploadDisabled(maxFiles - uploadFiles.length > 0 ? false : true)
    }, [uploadFiles, fileRejections, maxFiles])



    const thumbs = uploadFiles.map(file => (
        <div style={thumb} key={file.name}>
            <IconButton color="primary" component="span" style={thumbRemove} onClick={(e) => {
                onFileRemove(file)
            }}>
                <CloseIcon />
            </IconButton>

            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => uploadFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);



    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        // if (acceptedFiles.length) {
        //     setImages(acceptedFiles)
        //     setError(null);
        // } else if (fileRejections.length) {
        //     setImages(null);
        //     setError(fileRejections[0])
        // }

        // console.log("acceptedFiles", acceptedFiles)
        // console.log("fileRejections", fileRejections)

    }, [
        acceptedFiles,
        fileRejections
    ])

    const RejectFileView = fileRejections.map(({ file, errors }) => {
        return (
            <div style={{ color: "red" }}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>

            </div>
        )
    });

    return (
        <section className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p style={{
                    margin: 'auto',
                    fontSize: '20px',
                    textAlign: "center"
                }}>
                    {
                        uploadDisabled ?
                            `You have reached the maximum number of images.` :
                            `Drag and drop images.`
                    }</p>
                {error ? <aside>
                    {RejectFileView}
                </aside> : null
                }
            </div>

            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </section>
    );
}

export default UploadFile;



// const converPath = (path) => {
//     return `http://localhost:8000${path.slice(1, path.length)}`
// }

// const AcceptFileView = ({ file, setFile, coverPage }) =>

//     <Paper sx={{
//         minWidth: 175,
//         minHeight: 120,
//         display: 'flex',
//         alignItems: 'center',
//         m: 2,
//         p: 1
//     }}>
//         <Typography sx={{
//             fontSize: 20,
//             width: "100%",
//             m: 2
//         }}
//             align="center"
//             color="text.secondary"
//             gutterBottom>
//             <Box sx={{
//                 height: 150,
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//             }}>
//                 {coverPage ? <img
//                     src={`${converPath(coverPage.path)}?w=255&h=330&fit=crop&auto=format`}
//                     srcSet={`${converPath(coverPage.path)}?w=255&h=330&fit=crop&auto=format&dpr=2 2x`}
//                     loading="lazy"
//                     alt={coverPage.name}
//                     style={{ width: 75, margin: 15 }}
//                 /> :
//                     <PictureAsPdfIcon />}
//                 <Box sx={{
//                     whiteSpace: "nowrap",
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                     maxWidth: "calc(100% - 160px)",
//                     textAlign: "start",
//                     flexGrow: 2
//                 }} >
//                     {file.constructor == File ? file.name : file.filename}
//                 </Box>
//                 <Button sx={{
//                     alignSelf: "flex-end",
//                     flexGrow: 1,
//                     maxWidth: 120
//                 }}
//                     onClick={() => { setFile(null) }}
//                     variant="outlined" startIcon={<DeleteIcon />}>
//                     Delete
//                 </Button>
//             </Box>
//         </Typography>
//     </Paper>


// const AcceptFileViewJSON = ({ file }) => {
//     var fileObject = null
//     if (file.constructor == File) {
//         fileObject = {
//             'lastModified': file.lastModified,
//             'lastModifiedDate': file.lastModifiedDate,
//             'name': file.name,
//             'size': file.size,
//             'type': file.type
//         };
//     } else if (file.constructor == Object) {
//         fileObject = file
//     }
//     return <div><pre>{JSON.stringify(fileObject, null, 2)}</pre></div>
// }



// function bytesToSize(bytes) {
//     var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
//     if (bytes == 0) return '0 Byte';
//     var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
// }


// Preview Styles
const thumbRemove = {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 0,
    color: "red"
}

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: "relative"
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};