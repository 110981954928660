import "./index.css";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate
} from "react-router-dom";


import Login from './routes/Login'
import Home from './routes/Home'
import ExhibitItemList from './routes/ExhibitItemList'
import ExhibitItem from './routes/ExhibitItem'
import ThematicRouteList from './routes/ThematicRouteList'
import ThematicRoute from './routes/ThematicRoute'
import PrivateRoutes from './routes/PrivateRoutes'
import BeaconItem from './routes/BeaconItem'
import BeaconList from './routes/BeaconList'
import Layout from "./routes/Layout";
import ExhibitHighlighted from "./routes/ExhibitHighlighted";


const router = createBrowserRouter([
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'highlighted',
        element: <Layout />,
        children: [
          {
            index: true,
            element: <ExhibitHighlighted />,
          }
      ]
      },
      {
        path: 'exhibits',
        element: <Layout />,
        children: [
          {
            index: true,
            element: <ExhibitItemList />,
          },
          {
            path: 'create',
            element: <ExhibitItem />
          },
          {
            path: ':exhibitID',
            element: <ExhibitItem />
          },
        ]
      },
      {
        path: '/routes',
        element: <Layout />,
        children: [
          {
            index: true,
            element: <ThematicRouteList />,
          },
          {
            path: 'create',
            element: <ThematicRoute />
          },
          {
            path: ':routeID',
            element: <ThematicRoute />
          },  
        ]
      },
      {
        path: '/beacons',
        element: <Layout />,
        children: [
          {
            index: true,
            element: <BeaconList />,
          },
          {
            path: 'create',
            element: <BeaconItem />
          },
          {
            path: ':beaconID',
            element: <BeaconItem />
          },  
        ]
      }
    ]
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const App = () => {

  return <RouterProvider router={router} />
}


export default App
