import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


import { v4 as UUID } from 'uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageUploadForm from "../components/ImageUploadForm";
import MapViewer from "../components/MapViewer"


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


import Button from '@mui/material/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';


import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

import { Navigate, useNavigate } from 'react-router';


import { AppBarContext } from "../context/AppBarContext";

import { ExhibitAPI, RouteAPI, base_url } from "../api/"

export default () => {

    let { exhibitID } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const { setAppBarTitle, setAppBarSearch } = useContext(AppBarContext);
    const [openDataLangForm, setOpenDataLangForm] = useState(false);

    const [uuid, setUuid] = useState("")
    const [name, setName] = useState("");
    const [title, setTitle] = useState({ hr: "", en: "" });
    const [shortdesc, setShortdesc] = useState({ hr: "", en: "" });
    const [description, setDescription] = useState({ hr: "", en: "" });
    const [active, setActive] = useState(true);
    const [highlighted, setHighlighted] = useState(false);
    const [position, setPosition] = useState(null);
    const [audio, setAudio] = useState({
        hr: [],
        en: []
    })
    const [images, setImages] = useState([]);
    // Private
    const [inRoutes, setInRoutes] = useState([])


    // Upload
    const [uploadImages, setUploadImages] = useState([])
    const [uploadAudio, setUploadAudio] = useState({
        hr: [],
        en: []
    })

    // Route
    const [exhibitRouteList, setExhibitRouteList] = useState([])


    const handleCreate = () => {
        setLoading(true)
        ExhibitAPI.create({
            uuid,
            name,
            title,
            shortdesc,
            description,
            position,
            images,
            audio,
            active,
            highlighted
        }, uploadImages, uploadAudio).then(({ exhibit, error }) => {

            if (error) alert(error)
            else {
                setUploadImages([]);
                navigate(`../${exhibit.id || exhibit._id}`)
            }
        }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })

    }

    const handleUpdate = () => {
        setLoading(true)
        ExhibitAPI.update(exhibitID, {
            uuid,
            name,
            title,
            shortdesc,
            description,
            position,
            images,
            audio,
            active,
            highlighted
        }, uploadImages, uploadAudio).then(({ exhibit }) => {
            // Destruct exhibit
            setUuid(exhibit.uuid)
            setName(exhibit.name)
            setTitle(exhibit.title)
            setShortdesc(exhibit.shortdesc)
            setDescription(exhibit.description)
            setActive(exhibit.active)
            setImages(exhibit.images)
            setAudio(exhibit.audio)
            setInRoutes(exhibit.inRoutes)


            setUploadAudio([]);
            setUploadImages([]);
            setAppBarTitle(exhibit.name)

            alert("Saved")

        }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleRemove = () => {
        setLoading(true)
        ExhibitAPI.remove(exhibitID)
            .then(({ exhibit }) => {
                navigate(`../`)
            }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleGetByID = () => {
        setLoading(true)
        ExhibitAPI.getByID(exhibitID)
            .then(({ exhibit }) => {
                setUuid(exhibit.uuid)
                setName(exhibit.name)
                setTitle(exhibit.title)
                setShortdesc(exhibit.shortdesc)
                setDescription(exhibit.description)
                setActive(exhibit.active)
                setHighlighted(exhibit.highlighted || false)
                setImages(exhibit.images)
                setAudio(exhibit.audio)
                setPosition(exhibit.position)
                setInRoutes(exhibit.inRoutes)

                setAppBarTitle(exhibit.name)

                RouteAPI.getList().then(({ list }) => {
                    setExhibitRouteList(list.filter(i => exhibit.inRoutes.includes((i.id || i._id)))
                        .map(i => {
                            return {
                                id: i.id || i._id,
                                name: i.name
                            }
                        }))
                }).catch(e => console.log(e))



            }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }



    useEffect(() => {
        setAppBarSearch(null)
        if (exhibitID) {
            handleGetByID()
        } else {
            setUuid(UUID())
            setAppBarTitle("Add new Exhibit")
        }
    }, [exhibitID])



    return (

        <Grid container >
            <Row>
                <Grid md={6} >
                    <NameForm name={name}
                        setName={setName}
                        active={active}
                        setActive={setActive}
                        highlighted={highlighted}
                        setHighlighted={setHighlighted}
                    />
                </Grid>
                <Grid md={6} >
                    <ButtonControls exhibitID={exhibitID}
                        handleUpdate={handleUpdate}
                        handleCreate={handleCreate}
                        handleRemove={handleRemove} />

                </Grid>
            </Row>

            <SectionTitle title="Text Content" />
            <Row>
                <DataLangForm lang={"hr"}
                    open={openDataLangForm}
                    onOpenChange={setOpenDataLangForm}
                    title={title}
                    setTitle={setTitle}
                    shortdesc={shortdesc}
                    setShortdesc={setShortdesc}
                    description={description}
                    setDescription={setDescription}
                />
                <DataLangForm lang={"en"}
                    open={openDataLangForm}
                    onOpenChange={setOpenDataLangForm}
                    title={title}
                    setTitle={setTitle}
                    shortdesc={shortdesc}
                    setShortdesc={setShortdesc}
                    description={description}
                    setDescription={setDescription}
                />
            </Row>


            <SectionTitle title="Map" />
            <Row >
                <MapViewer position={position} setPosition={setPosition} />
            </Row>


            <SectionTitle title="Audio" />
            <Row>
                <AudioForm lang={'hr'} audio={audio} setAudio={setAudio} uploadAudio={uploadAudio} setUploadAudio={setUploadAudio} exhibitRouteList={exhibitRouteList} />
                <AudioForm lang={'en'} audio={audio} setAudio={setAudio} uploadAudio={uploadAudio} setUploadAudio={setUploadAudio} exhibitRouteList={exhibitRouteList} />
            </Row>

            <SectionTitle title="Images" />
            <Row>
                <ImageGallery
                    images={images}
                    setImages={setImages}
                />
            </Row>


            <Row>
                <ImageUploadDropzone
                    acceptType={{ 'image/*': [] }}
                    maxFiles={5 - images.length}
                    uploadFiles={uploadImages}
                    setUploadFiles={setUploadImages}
                />
            </Row>

        </Grid >
    )
}


const ButtonControls = ({ exhibitID, handleCreate, handleUpdate, handleRemove }) => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "auto",
            ml: 2,
            mr: 2
        }}>
            {exhibitID ?
                <>
                    <Button sx={{ m: 1 }} color="success" variant="contained" onClick={handleUpdate}>Update</Button>
                    <Button sx={{ m: 1 }} color="error" variant="contained" onClick={handleRemove}>Remove</Button>
                </>
                :
                <>
                    <Button sx={{ m: 1 }} color="success" variant="contained" onClick={handleCreate}>Create</Button>
                </>}

        </Box>
    )
}

const NameForm = ({ name, setName, active, setActive, highlighted, setHighlighted }) => {
    console.log("highlighted",highlighted);
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "auto",
            ml: 2,
            mr: 2
        }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={6}>
                    <TextField id="standard-basic" fullWidth label="Name" variant="standard" value={name} onChange={e => setName(e.target.value)} />
                </Grid>
                <Grid xs={3}>
                    <FormGroup sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                        <FormControlLabel control={<Switch defaultChecked checked={active} onChange={e => setActive(e.target.checked)} />} label="Active" />
                    </FormGroup>
                </Grid>
                <Grid xs={3}>
                    <FormGroup sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                        <FormControlLabel control={<Switch defaultChecked checked={highlighted} onChange={e => setHighlighted(e.target.checked)} />} label="Highlighted" />
                    </FormGroup>
                </Grid>
            </Grid>
        </Box>
    )
}

const DataLangForm = ({ lang, open, onOpenChange, ...props }) => {
    const {
        title,
        setTitle,
        shortdesc,
        setShortdesc,
        description,
        setDescription
    } = props

    return (
        <Box sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ml: 2,
            mr: 2
        }}>

            <Accordion sx={{
                width: "100%",
                marginBottom: "15px",
                p: 2
            }}
                expanded={open} onChange={() => { onOpenChange(!open) }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{`Description data ${lang.toUpperCase()}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField id="standard-basic" fullWidth
                        label="Title" variant="standard"
                        value={title[lang]}
                        onChange={e => setTitle({ ...title, [lang]: e.target.value })} />
                    <TextField
                        id="outlined-textarea"
                        label="Short description"
                        multiline
                        fullWidth
                        variant="standard"
                        value={shortdesc[lang]}
                        onChange={e => setShortdesc({ ...shortdesc, [lang]: e.target.value })}
                        rows={4}
                    />
                    <TextField
                        id="outlined-textarea"
                        label="Description"
                        multiline
                        fullWidth
                        variant="standard"
                        value={description[lang]}
                        onChange={e => setDescription({ ...description, [lang]: e.target.value })}
                        rows={8}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

const ImageUploadDropzone = (props) => {

    console.log(props)
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Paper
                elevation={3}
                sx={{
                    width: '100%',
                    maxWidth: '100%',
                    m: 1,
                    p: 2
                }}
            >
                <ImageUploadForm  {...props} />
            </Paper>
        </Box>
    )
}
const ImageGallery = ({ images, setImages }) => {
    return (
        <ImageList sx={{}} variant="standard" cols={4} gap={2}>
            {/* <ImageListItem key="Subheader" cols={4}>
                <ListSubheader component="div">Images</ListSubheader>
            </ImageListItem> */}
            {images.map((item) => (
                <ImageListItem key={item.img} sx={{ maxHeight: "345px", maxWidth: "345px", overflow: "hidden" }}>
                    <img
                        src={`${converPath(item.path)}?w=248&fit=crop&auto=format`}
                        srcSet={`${converPath(item.path)}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.name}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        position="top"
                        // title={item.name}
                        actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about ${item.title}`}
                                onClick={() => {
                                    const index = images.indexOf(item)
                                    if (index != -1) {
                                        images.splice(index, 1)
                                        setImages([...images])
                                    }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                </ImageListItem>
            ))}
        </ImageList>
    )
}


const AudioForm = ({ lang, audio, setAudio, uploadAudio, setUploadAudio, exhibitRouteList }) => {


    const audioLang = useMemo(()=>{
        return audio[lang]
    }, [audio])


    const pickRouteAudio = (routeID, audioList) => {
        return audioList.find(({ route }) => route === routeID)
    }

    const handleUploadAudioChange = (e) => {
        const file = e.target.files[0];
        const { name } = e.target;

        let overwriteIndex = uploadAudio[lang].findIndex(a => a.route == name)
        if (overwriteIndex != -1) {
            uploadAudio[lang][overwriteIndex] = { route: name, file }
        }
        else {
            uploadAudio[lang] = [...uploadAudio[lang], { route: name, file }]
        }
        setUploadAudio({ ...uploadAudio, [lang]: uploadAudio[lang] });
    }

    const removeAudio = (routeID) => {

        let sc_audioLang = [...audioLang]
        let index = sc_audioLang.findIndex(audio => audio.route == routeID)
        if(index != -1 ) {
            sc_audioLang.splice(index, 1)
        }
        setAudio({...audio, [lang]: sc_audioLang})
    }

    let DefaultItem = (<ListItem>
        <ListItemIcon>
            <AudiotrackIcon />
        </ListItemIcon>
        <ListItemText primary="Default" sx={{ mr: 4 }} />
        {pickRouteAudio("default", audioLang) ?
         <>
         <AudioPlayer
            autoPlay = {false}
            showJumpControls = {false}
            showDownloadProgress = {false}
            showFilledProgress = {false}
            layout = {"horizontal"}
            // customProgressBarSection = {[null]}
            // customControlsSection = {[null]}
            customVolumeControls = {[null]}
            customAdditionalControls = {[null]}
            src={converPath(pickRouteAudio("default", audioLang).file.path)}
            onPlay={e => console.log("onPlay")}
            // other props here
        />
            <ListItemIcon>
                <DeleteIcon onClick={()=> removeAudio("default")}/>
            </ListItemIcon> 
        </>: <input type="file" accept="audio/*" name="default" onChange={handleUploadAudioChange} />}
    </ListItem>)

    let ListItemRoute = exhibitRouteList.map(route => {
        return (<ListItem>
            <ListItemIcon>
                <AudiotrackIcon />
            </ListItemIcon>
            <ListItemText primary={route.name} sx={{ mr: 4 }} />
            {pickRouteAudio(route.id || route._id, audioLang) ? 
            <>
              <AudioPlayer
            autoPlay = {false}
            showJumpControls = {false}
            showDownloadProgress = {false}
            showFilledProgress = {false}
            layout = {"horizontal"}
            // customProgressBarSection = {[null]}
            // customControlsSection = {[null]}
            customVolumeControls = {[null]}
            customAdditionalControls = {[null]}
            src={converPath(pickRouteAudio(route.id || route._id, audioLang).file.path)}
            onPlay={e => console.log("onPlay")}
            // other props here
        />
            <ListItemIcon>
                <DeleteIcon onClick={()=> removeAudio(route.id || route._id)}/>
            </ListItemIcon> </>: <input type="file" accept={"audio/*"} name={route.id || route._id} onChange={handleUploadAudioChange} />}


        </ListItem>)

    })
    // Object.keys(audio).forEach(key => {

    // })

    return (<List>
        <ListSubheader component="div" >
            {`Audio files ${lang.toUpperCase()}`}
        </ListSubheader>
        {[DefaultItem, ...ListItemRoute]}
    </List>)
}


const SectionTitle = ({ title }) => {

    return (<Row style={{ backgroundColor: "#1e76d28f", padding: "10px" }}>
        <Typography variant="h5" noWrap component="div" sx={{ textTransform: "uppercase", width: "100%" }}>
            {title}
        </Typography>
    </Row>)
}


const Row = ({ children, style }) => {
    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            mt: 3,
            mb: 3,
            ...style
        }}>
            {children}
        </Box>
    )
}


const converPath = (path) => {
    return `${base_url}${path}`
}