import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom"

import { v4 as uuid } from 'uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageUploadForm from "../components/ImageUploadForm";
import MapViewer from "../components/MapViewer"


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


import Button from '@mui/material/Button';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

import { Navigate, useNavigate } from 'react-router';


import { AppBarContext } from "../context/AppBarContext";

import { BeaconAPI, base_url } from "../api/"

import DNDList from '../components/DNDList'
import SearchTable from '../components/SearchTable'


export default () => {

    let { beaconID } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [openDataLangForm, setOpenDataLangForm] = useState(false);
    const { setAppBarTitle, setAppBarSearch } = useContext(AppBarContext);



    const [name, setName] = useState("");
    const [uuid, setUuid] = useState("");
    const [position, setPosition] = useState(null)
    



    const handleCreate = () => {
        setLoading(true)
        BeaconAPI.create({
            name,
            uuid,
            position
        }).then(({ beacon, error }) => {

            if (error) alert(error)
            else {
                navigate(`../${beacon.id || beacon._id}`)
            }
        }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })

    }

    const handleUpdate = () => {
        setLoading(true)
        BeaconAPI.update(beaconID, {
            name,
            uuid,
            position
        }).then(({ beacon, error }) => {

            if (error) alert(error)
            else alert("Saved")
            // Destruct route
            setName(beacon.name)
            setUuid(beacon.uuid)
            setPosition(beacon.position)
            setAppBarTitle(beacon.name)
        }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleRemove = () => {
        setLoading(true)
        BeaconAPI.remove(beaconID)
            .then(({ beacon }) => {
                navigate(`../`)
            }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleGetByID = () => {
        setLoading(true)
        BeaconAPI.getByID(beaconID)
            .then(({ beacon }) => {
                setName(beacon.name)
                setUuid(beacon.uuid)
                setPosition(beacon.position)
                setAppBarTitle(beacon.name)
            }).catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setAppBarSearch(null)
        if (beaconID) {
            handleGetByID()
        } else {
            setAppBarTitle("Add new Route")
        }
    }, [beaconID])



    return (

        <Grid container >
            <Row>
                <Grid md={6} >
                    <DataForm name={name}
                        setName={setName}
                        uuid={uuid}
                        setUuid={setUuid}
                    />
                </Grid>
                <Grid md={6} >
                    <ButtonControls beaconID={beaconID}
                        handleUpdate={handleUpdate}
                        handleCreate={handleCreate}
                        handleRemove={handleRemove} />

                </Grid>
            </Row>
           
            
            <Row style={{ width: "90%", margin: "auto" }}>
                <MapViewer position={position} setPosition={setPosition} />
            </Row>
            <PositionForm position={position} setPosition={setPosition}/>

            

        </Grid >
    )
}


const ButtonControls = ({ beaconID, handleCreate, handleUpdate, handleRemove }) => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "auto",
            ml: 2,
            mr: 2
        }}>
            {beaconID ?
                <>
                    <Button sx={{ m: 1 }} color="success" variant="contained" onClick={handleUpdate}>Update</Button>
                    <Button sx={{ m: 1 }} color="error" variant="contained" onClick={handleRemove}>Remove</Button>
                </>
                :
                <>
                    <Button sx={{ m: 1 }} color="success" variant="contained" onClick={handleCreate}>Create</Button>
                </>}

        </Box>
    )
}


const DataForm = ({ name, setName, uuid, setUuid}) => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "auto",
            ml: 2,
            mr: 2
        }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={8}>
                    <TextField id="standard-basic" fullWidth label="Name" variant="standard" value={name} onChange={e => setName(e.target.value)} />
                </Grid>
                <Grid xs={8}>
                    <TextField fullWidth label="UUID" variant="standard" value={uuid} onChange={e => setUuid(e.target.value)} />
                </Grid>
            </Grid>
        </Box>
    )
}



const PositionForm = ({ position, setPosition}) => {

    

    const onPositionChange = (e) => {

        setPosition({
            ...position,
            [e.target.name]: e.target.value
        })
        
        console.log(e.target.name)
        console.log(e.target.value)
    }


    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            margin: "auto",
            ml: 2,
            mr: 2,
            
        }}>
            
                    <TextField sx={ {m: 1}} id="standard-basic" name="x"  label="X" variant="standard" value={position? position.x: 0} onChange={onPositionChange} />
                
                    <TextField sx={ {m: 1}} id="standard-basic" name="y"  label="Y" variant="standard" value={position? position.y: 0} onChange={onPositionChange} />
                    <TextField sx={ {m: 1}} id="standard-basic" name="z"  label="Z" variant="standard" value={position? position.z: 0} onChange={onPositionChange} />
                
        </Box>
    )
}


const Row = ({ children, style }) => {
    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            mt: 3,
            mb: 3,
            ...style
        }}>
            {children}
        </Box>
    )
}


const converPath = (path) => {
    return `${base_url}${path}`
}