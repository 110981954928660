import { useState } from 'react';
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';


import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


import { styled } from '@mui/material/styles';

import ArmusLogo from '../armus-logo.png'


import { AdminAPI, base_url } from "../api/"

const AdminLogin = () => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()

    const login = (email, password) => {
        AdminAPI.login(email, password)
            .then(({ user, error }) => {
                if (user) {
                    navigate('/')
                } else if (error) {
                    setError(error)
                }
            }).catch(e => console.log(e))
    }



    const onEmailChange = (e) => {
        setEmail(e.target.value)
        setError("")
    }
    const onPasswordChange = (e) => {
        setPassword(e.target.value)
        setError("")
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container maxWidth="sm">

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                m: 2
            }}>

                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>

                    <img src={ArmusLogo} className="logo" alt="logo" style={{ maxHeight: 200 }} />
                </Box>
            </Box>

            {/* <Paper elevation={3} sx={{mt: 8, p:4}}> */}
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, mt: 8, maxWidth: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <Stack spacing={4}>
                    <TextField id="user-email" fullWidth value={email} onChange={onEmailChange} label="Korisničko ime ili email adresa *" variant="standard" />
                    <FormControl fullwidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">Zaporka</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={onPasswordChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    {error ? <span style={{
                        color: "red",
                        marginTop: "10px",
                        fontSize: "12px"
                    }}> {error}</span> : null}
                    <Button variant="outlined" onClick={() => { login(email, password) }}>Prijava</Button>
                </Stack>
            </Box>
            {/* </Paper> */}
        </Container>
    )



}


export default AdminLogin