import {createContext, useState} from "react";

export const AppBarContext = createContext();

export function AppBarContextProvider({ children }) {
  const [appBarTitle, setAppBarTitle] = useState("");
  
  const [appBarSearch, setAppBarSearch] = useState(null);


  const defaultContext = {
    appBarTitle,
    setAppBarTitle,
    appBarSearch,
    setAppBarSearch
  };
  
  return (
    <AppBarContext.Provider value={defaultContext}>
      {children}
    </AppBarContext.Provider>
  );
}

