import { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import AppBar from '../components/AppBar'
import Drawer from '../components/Drawer'
import PersistentDrawer from '../components/PersistentDrawer'

import { AdminAPI, base_url } from "../api/"
import { AppBarContextProvider } from "../context/AppBarContext";

const PrivateRoutes = (aaa) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);
        AdminAPI.authorise().then((user) => {
            setUser(user)
            setLoading(false);
        }).catch(error => console.log(error))
    }, [])

    if (loading) return <div>Loading ... </div>
    return (
        user ? (
            <AppBarContextProvider>

                <PersistentDrawer >
                    <Outlet />
                </PersistentDrawer>

            </AppBarContextProvider>) : <Navigate to="login" />
    )
}

export default PrivateRoutes